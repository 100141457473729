import React, { useRef, useState } from "react";
import "./form-section.scss";
import { Heading } from "../Heading/Heading";
import { Button } from "../Button/Button";
import Obfuscate from "react-obfuscate";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DropzoneComponent from "react-dropzone-component";
import "../../../node_modules/dropzone/dist/min/dropzone.min.css";
import axios from "axios";

const phoneRegExp = /^[0-9 ()+-]+$/;
const schema = yup
  .object({
    name: yup
      .string()
      .required("Please tell us your name")
      .min(3, `Your name has to have at least 3 letters`),
    email: yup
      .string()
      .email("Please check your email, its not valid")
      .required("We need your email to contact you"),
    phone: yup
      .string()
      .required("We need your phone to contact you")
      .matches(phoneRegExp, `Please type in a valid phone number`)
      .min(5, `Your phone has to have at least 5 numbers`),
    company: yup.string(),
    message: yup.string(),
    gdpr: yup.boolean().oneOf([true], "Please accept our privacy policy"),
  })
  .required();

const FormSection = ({ title }) => {
  const dropzoneRef = useRef();
  const {
    register,
    handleSubmit,
    onError,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const showSuccessMessage = () => {
    setIsSuccess(true);
    setTimeout(() => setIsSuccess(false), 5000);
  };

  const dropzoneConfig = {
    postUrl: `${process.env.GATSBY_API_URL}/contact-form`,
  };
  const djsConfig = {
    maxFilesize: 10,
    parallelUploads: 6,
    maxFiles: 6,
    acceptedFiles: ".docx,.doc,.pdf",
    autoProcessQueue: false,
    addRemoveLinks: true,
    uploadMultiple: true,
    paramName: () => "file",
  };

  const handleInit = (dropzoneInstance) => {
    dropzoneRef.current = dropzoneInstance;
  };

  const handleSendingMultiple = (data, xhr, formData) => {
    setLoading(true);
    setIsError(false);
    setIsSuccess(false);
    const formValues = getValues();
    for (const key in formValues) {
      if (key === "field") {
        formData.append(key, formValues[key][1]);
      } else {
        formData.append(key, formValues[key]);
      }
    }
  };

  const handleSubmitSuccessful = () => {
    setLoading(false);
    showSuccessMessage();

    reset({
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      gdpr: false,
      files: "",
    });
    dropzoneRef.current.removeAllFiles();
  };

  const handleSubmitError = () => {
    setLoading(false);
    setIsError(true);
  };

  const eventHandlers = {
    init: handleInit,
    sendingmultiple: handleSendingMultiple,
    successmultiple: handleSubmitSuccessful,
    error: handleSubmitError,
  };

  const onSubmit = (data) => {
    if (dropzoneRef.current?.getQueuedFiles().length > 0) {
      dropzoneRef.current?.processQueue();
    } else {
      const formData = new FormData();
      for (const key in data) {
        if (key === "field") {
          formData.append(key, data[key][1]);
        } else {
          formData.append(key, data[key]);
        }
      }

      axios
        .post(`${process.env.GATSBY_API_URL}/contact-form`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          handleSubmitSuccessful();
        })
        .catch((error) => {
          setIsError(true);
        });
    }
  };

  return (
    <>
      <div className="form-section">
        <div className="container ">
          <div className="gr-12 no-gutter@md">
            <div className="form-section__wrap">
              <div className="form-section__contact-details">
                <div className="holder">
                  <Heading level="h3" white small decorated>
                    Get in touch
                  </Heading>
                  <div className="details">
                    <span className="title">Office</span>
                    <p className="text">Radnička cesta 47</p>
                    <p className="text">10000 Zagreb, Croatia</p>
                    <span className="title">E-mail</span>
                    <Obfuscate
                      className="link"
                      email="info@kickstage.com"
                      rel="nofollow"
                    />
                    <span className="title">Social</span>
                    <a
                      href="https://www.instagram.com/kickstagecom/"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Instagram
                    </a>
                    <a
                      href="https://www.facebook.com/kickstagedevs/"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Facebook
                    </a>
                    <a
                      href="https://hr.linkedin.com/company/kickstage?trk=public_profile_topcard-current-company"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      Linkedin
                    </a>
                    <a
                      href="https://www.tiktok.com/@kickstagecom"
                      target="_blank"
                      rel="noreferrer"
                      className="link"
                    >
                      TikTok
                    </a>
                  </div>
                </div>
              </div>
              <div className="form-section__form-holder">
                <Heading level="h4" decorationDown>
                  {title}
                </Heading>
                <div role="form" className="form">
                  <form
                    onSubmit={(e) => {
                      handleSubmit(onSubmit)(e);
                    }}
                    method="POST"
                  >
                    <div className="row">
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <input
                            type="text"
                            className={errors.name && "error"}
                            {...register("name")}
                            size="40"
                            placeholder="Full name"
                          />
                          <label htmlFor="name">Full name</label>
                          <label className="error">
                            {errors.name?.message}
                          </label>
                          {errors.name && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <input
                            type="email"
                            className={errors.email && "error"}
                            {...register("email")}
                            size="40"
                            placeholder="Email"
                          />
                          <label htmlFor="email">Email</label>
                          <label className="error">
                            {errors.email?.message}
                          </label>
                          {errors.email && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <input
                            type="tel"
                            className={errors.phone && "error"}
                            {...register("phone")}
                            size="40"
                            placeholder="Phone number"
                          />
                          <label htmlFor="phone">Phone number</label>
                          <label className="error">
                            {errors.phone?.message}
                          </label>
                          {errors.phone && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <input
                            type="text"
                            className={errors.company && "error"}
                            {...register("company")}
                            size="40"
                            placeholder="Company name"
                          />
                          <label htmlFor="company">Company name</label>
                          <label className="error">
                            {errors.company?.message}
                          </label>
                          {errors.company && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="gr-12">
                        <fieldset className="form__fieldset form__fieldset--about">
                          <textarea
                            {...register("message")}
                            placeholder="Tell us about your project"
                            rows="4"
                          />
                          <label htmlFor="message">
                            Tell us about your project
                          </label>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="gr-12">
                        <fieldset className="form__fieldset form__fieldset--dropzone">
                          <DropzoneComponent
                            config={dropzoneConfig}
                            eventHandlers={eventHandlers}
                            djsConfig={djsConfig}
                          >
                            <div className="dz-message">
                              Attach any files you feel would be useful
                              (.docx,.doc,.pdf)
                            </div>
                          </DropzoneComponent>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="gr-12">
                        <fieldset className="form__fieldset form__fieldset--gdpr">
                          <div className="checkbox-group">
                            <input
                              type="checkbox"
                              {...register("gdpr")}
                              id="gdpr"
                            />
                            <label htmlFor="gdpr">
                              I consent to Kickstage contacting me regarding the
                              message I sent.
                            </label>
                            <label className="error">
                              {errors.gdpr?.message}
                            </label>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      {isSuccess && (
                        <div className="gr-12">
                          <div className="form__notification sucess">
                            <span>
                              Your inquiry has been sent. We'll be in touch!
                            </span>
                          </div>
                        </div>
                      )}
                      {isError && (
                        <div className="gr-12">
                          <div className="form__notification error">
                            <span>
                              Something went wrong. Please try again later.
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="gr-12">
                        <Button
                          type="submit"
                          submitBtn
                          primary
                          loading={loading}
                          disabled={loading}
                          data-sitekey="6Ld-SrEZAAAAAD9uhrzNWBaTDuQRCTuFQnKI4z54"
                          data-callback="recaptchaCallback"
                          data-badge="inline"
                          data-size="invisible"
                          onClick={() => handleSubmit(onSubmit, onError)}
                        >
                          {loading ? "Sending..." : `Let's chat`}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSection;
