import React, { Fragment } from 'react';
import { graphql, useStaticQuery } from "gatsby";
import Layout from '../components/layout/layout'
import Fold from '../components/Fold/Fold';
import FormSection from '../components/FormSection/FormSection';
import FadeDown from '../components/FadeDown/FadeDown';
import { OurWorkSection } from '../components/OurWorkSection/OurWorkSection';
import CtaSection from '../components/CtaSection/CtaSection';

const IMAGE_QUERY = graphql`
{
  ourWorkImage: file(relativePath: {eq: "our-work.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 1200
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
  ourWorkImageMobile: file(relativePath: {eq: "about-us/our-work-mobile.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 768
        placeholder: BLURRED
        formats: [AUTO, WEBP, AVIF]
      )
    }
  }
  bottomImg: file(relativePath: {eq: "footer-logo.png"}) {
    childImageSharp {
      gatsbyImageData(
        width: 1183,
        placeholder: BLURRED
      )
    }
  }
}
`

const Contact = () => {
  const tempImage = useStaticQuery(IMAGE_QUERY);

  return (
    <Layout
      pageTitle="Contact"
      description="Need to plan, create, or redefine a digital project so you can concentrate on your core business? We can help! Pop in for coffee at our office or simply reach us using the details below."
    >
      <FadeDown fold>
        <Fold
          title={<Fragment>Let's <span>talk</span></Fragment>}
          description={<Fragment>Need to plan, create, or redefine a digital project so you can concentrate on your core business? <span>We can help!</span> Pop in for coffee at our office or simply reach us using the details below.</Fragment>}
          centered
          small
        >
        </Fold>
      </FadeDown>
      <FadeDown>
        <FormSection
          title="Ask us a question"
        />
      </FadeDown>
      <FadeDown>
        <OurWorkSection
          title={<Fragment><span>Our</span> Work</Fragment>}
          ctaText="See all projects"
          link="/our-work/"
          image={tempImage.ourWorkImage}
          mobileImage={tempImage.ourWorkImageMobile}
        />
      </FadeDown>
      <CtaSection
        title="Join us"
        label="Want to work with us?"
        link="/careers/#job-listing"
        image={tempImage.bottomImg}
      />
    </Layout>
  );
};

export default Contact;
